import toastr from "toastr";
import { customToastr } from "../_Pages/_webapp/arya2.0/CustomToastr";
function FilterResponse(response) {
  try {
    if (response == "CANCELLED") return "CANCELLED";
    if (response.data.status_code >= 200 && response.data.status_code <= 298) {
      return response;
    } else {
      console.log("response.data.message", response.data);
      if (response.data.status_code === 419) {
        return response;
      } else {
        customToastr({
          type: "warning",
          timeOut: 3000,
          text: response.data.message
            ? response.data.message
            : response.data.msg
            ? response.data.msg
            : "Server Error",
        });
        // toastr.warning(
        //   response.data.message
        //     ? response.data.message
        //     : response.data.msg
        //     ? response.data.msg
        //     : "Server Error"
        // );
        return false;
      }
    }
  } catch (error) {
    // toastr.error("Something Went Wrong")
    console.log("error", error.response, response);
  }
}
export default FilterResponse;

export function FilterResponseV2(response) {
  try {
    if (
      response?.data?.success?.status >= 200 &&
      response?.data?.success?.status <= 298
    ) {
      return response.data;
    } else {
      console.log("Sucess in FilterResponseV2:---", response.data);
      if (response?.data?.success?.status === 419) {
        return response;
      } else {
        console.log(
          "Error Message in FilterResponseV2:---",
          response?.data?.error?.message
        );
        // toastr.warning(
        // response?.data?.error?.message
        //   ? response?.data?.error?.message
        //   : "Server Error"
        // );
        customToastr({
          type: "warning",
          timeOut: 3000,
          text: response?.data?.error?.message
            ? response?.data?.error?.message
            : "Server Error",
        });
        return false;
      }
    }
  } catch (error) {
    console.log("error", error.response, response);
  }
}
