import { SET_USER_INFO, UPDATE_USER_INFO } from "./types";

const initialState = {};

export const userInfoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_INFO:
      return { ...payload };
    case UPDATE_USER_INFO:
      return { ...state, ...payload };
    default:
      return state;
  }
};
